.dashboard-transition {
  transition: 1s cubic-bezier(0.4, 0.1, 0.4, 1) !important;
}

.dashboards-styles {
  --color-bg: #f9f9f9;
  --color-primary: #6c9bcf;
  --color-danger: #ff0060;
  --color-success: #1b9c85;
  --color-warning: #f7d060;
  --color-white: #fff;
  --color-info-dark: #7d8da1;
  --color-dark: #363949;
  --color-light: rgba(132, 139, 200, 0.18);
  --color-shadows: 0 2rem 2rem #0c1014;
  --color-dark-variant: #677483;
  --color-background: #f6f6f9;
  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 1.2rem;
  --card-padding: 1.5rem;
  --padding-1: 1.2rem;
  --box-shadows: 0 2rem 3rem var(--color-light);
  /*
    sidebars */

  --text-color: #000;
  --sidebar-color: #fff;
  --primary-color: var(--pink);
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;

  --toggle-arrow-color: #fff;
  --trans-02: all 0.2s ease;
  --trans-03: all 0.3s ease;
  --trans-04: all 0.4s ease;
  --trans-05: all 0.5s ease;
  --text-color: #000;
  --main-hue: 208;
  --main-hue-2: 331;
  --main-hue-3: 213;

  --form-transition: 1s cubic-bezier(0.4, 0.1, 0.4, 1) !important;
  --main-color-2: hsl(var(--main-hue), 92%, 54%);
  --main-color: hsl(var(--main-hue-2), 100%, 50%);
  --main-color-hover: hsl(var(--main-hue), 92%, 54%);
  --nav-color: hsl(var(--main-hue), 17%, 79%);
  --bg-color: #f9f9f9;
  --light-text-color: #9ca7b6;
  --light-main-color: hsl(var(--main-hue), 91%, 55%);
  --border-color: hsl(331.29deg 100% 50%);
  --main-color-change: hsl(331.29deg 100% 50%);
  --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
  --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
  --input-text: #d5d7da;
  --input-shadow: hsla(var(--main-hue), 92%, 54%, 0.169);
  --form-bg-opacity: 0;
  --checkbox-color: #f0f0f0;
  --form-bg-color: transparent;
  --transform-img: translate(-50%, -50%) scale(1.4);
  --icon-check-color: var(--main-color);
  --box-shadow-color: var(--main-hue-2);

  &.darkmode {
    --color-bg: hsl(213deg 33.27% 6.02%);
    --color-background: #181a1e;
    --color-white: transparent
      linear-gradient(180deg, #111823 0%, #131c2b 0%, #101723 100%);
    --color-dark: #edeffd;
    --color-dark-variant: #a3bdcc;
    --color-light: rgba(0, 0, 0, 0.4);
    --box-shadows: var(--color-shadows);
    --border-card: 1px solid hsl(331.29deg 35.03% 82.52% / 25%);

    /* sidebar */
    --sidebar-color: #242526;
    --primary-color-light: #3a3b3c;
    --toggle-color: #fff;
    --toggle-arrow-color: #ccc;
    --text-color: #fff;
    --checkbox-color: #f0f0f0f7;
    --box-shadow-color: var(--main-hue);
    --main-color-change: var(--light-main-color);
    --box-shadow: 0 0 0 5px hsla(var(--main-hue), 91%, 55%, 0.11);
    --bg-color: hsl(var(--main-hue-3), 35%, 6%);
    --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
    --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
    --nav-color: hsl(var(--main-hue), 10%, 47%);
    --light-text-color: hsl(var(--main-hue), 12%, 46%);
    --border-color: var(--light-main-color);
    --input-text: hsl(var(--main-hue), 10%, 55%);
    --input-shadow: hsla(var(--main-hue), 92%, 58%, 0.2);
    --form-bg-opacity: 1;
    --form-bg-color: hsla(var(--main-hue), 30%, 16.5%, 60%);
    --transform-img: translate(-50%, -50%) scale(1.7);
    --icon-check-color: #26ffed;

    .model_dashboard {
      #pane {
      }

      #area_one {
      }

      .profile {
        &:hover {
        }

        .head {
          .profile_img {
            .ppLabel {
              .photo_icon {
              }
            }
          }
        }

        .body {
          .name {
            color: var(--color-dark);
          }
          .model {
          }

          .edit_holder {
            color: var(--color-dark);
            .edit {
            }
          }

          .mDet {
            gap: 6px;
            label {
              color: var(--color-dark);
            }

            input,
            select {
            }

            .update {
            }
          }
        }
      }

      #latest_post {
        &:hover {
        }

        #body {
        }
      }

      #area_two {
      }
    }

    #bookings {
    }

    .earnings {
      color: var(--color-dark);
      &:hover {
      }
    }

    .visitor_stats {
      &:hover {
      }
    }

    #area_three {
    }

    #job_posted {
      &:hover {
      }

      #body {
      }
    }

    #inbox {
      &:hover {
      }

      header {
      }

      header span {
      }

      .msg {
      }

      .msg .notification {
      }
    }

    .top_rated {
      &:hover {
      }

      .body {
      }
      .body > div {
      }
      .img_holder {
      }
      .name {
        color: var(--color-dark);
      }

      &.one {
      }
    }
  }
}
