@import "../scss/color";

@mixin img_holder($w: 70px, $h: 70px) {
  display: inline-block;
  width: $w;
  height: $h;
  border-radius: 50%;
  overflow: hidden;
  min-width: $w;
  min-height: $h;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin d_flex($align: center, $justify: center, $dir: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $dir;
}

@mixin mq($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin mq-max($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin input_select {
  font-size: 0.94rem;
  font-weight: 700;
  color: $dark_gray;
}

@mixin table_base {
  border-collapse: collapse;
  font-size: 0.8rem;
  width: 100%;
  background-color: $white;
  table-layout: fixed;
  min-width: 540px;
}

@mixin box_header($x: 1em, $y: 0.5em, $color: $deep_pink, $fs: 0.74rem) {
  header {
    padding: $y $x;
    @include d_flex($justify: space-between);
    border-bottom: 1px solid $light_gray;
  }

  h4 {
    color: $color;
  }
  a {
    font-weight: 500;
    color: $dim_black;
    font-size: $fs;
  }
}

@mixin visitor_stats {
  background-color: $white;
  border-radius: $box_radius;
  header {
    padding: $big_padding;
    @include d_flex($justify: space-between);
    border-bottom: 1px solid $light_gray;
  }

  select {
    @include input_select;
  }

  h4 {
    color: $deep_pink;
  }

  #key_holder {
    @include d_flex($justify: flex-end);
    font-size: 0.8rem;
    padding: 0.5em calc(1em + 6px);
  }

  #key_holder > span {
    @include d_flex;
    margin-left: 1em;
    font-weight: 600;
  }

  #key_holder > span:nth-of-type(1) {
    color: $green;
  }

  #views {
    color: $black;
  }

  #key_holder > span > span:not(#key) {
    margin-inline: 0.5em;
  }

  #key {
    background-color: $light_gray;
    width: 3em;
    height: 0.6em;
    border-radius: 1em;
    margin-left: 0.5em;
  }
  #bar_chart_holder {
    max-width: 100%;
    @include mq($width: 1050px) {
      min-height: max(40vh, 270px);
    }
  }

  #visitors_bar_chart {
    padding-inline: 1em;
    height: max(40vh, 230px);
    @include mq($width: 540px) {
      max-height: 230px;
    }
    @include mq($width: 1050px) {
      max-height: unset;
    }
  }
  #bottom {
    padding: 1em 2em;
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
  }
  #bottom > span {
    margin-inline: 0.8em 2em;
  }
}
