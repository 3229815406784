.Test0j {
  background-color: #121212;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  .dashboard-test {
    /* General Styles */

    /* Card Styling */
    .card {
      background: linear-gradient(145deg, #1e1e1e, #292929);
      border-radius: 12px;
      padding: 20px;
      box-shadow: 5px 5px 10px #0d0d0d, -5px -5px 10px #1a1a1a;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

    .card:hover {
      transform: translateY(-5px);
      box-shadow: 10px 10px 20px #0d0d0d, -10px -10px 20px #1a1a1a;
    }

    /* Button Styling */
    button {
      background: #ff6b81;
      border: none;
      color: white;
      padding: 10px 15px;
      border-radius: 8px;
      cursor: pointer;
      transition: background 0.3s ease-in-out;
    }

    button:hover {
      background: #ff4757;
    }

    /* Animations */
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(10px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .fade-in {
      animation: fadeIn 0.5s ease-in-out;
    }

    /* Stats Boxes */
    .stats-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #1e1e1e;
      padding: 15px;
      border-radius: 10px;
      box-shadow: inset 2px 2px 5px #0d0d0d, inset -2px -2px 5px #292929;
    }

    /* Visitor Stats */
    .visitor-stats {
      padding: 20px;
      background: #181818;
      border-radius: 12px;
      box-shadow: 5px 5px 10px #0d0d0d, -5px -5px 10px #1a1a1a;
    }

    /* Profile Section */
    .profile-card {
      text-align: center;
      background: #1c1c1c;
      padding: 20px;
      border-radius: 12px;
      box-shadow: 3px 3px 6px #0d0d0d, -3px -3px 6px #292929;
    }

    .profile-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 3px solid #ff6b81;
    }

    /* Responsive Design */
    @media (max-width: 768px) {
      .stats-box {
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
