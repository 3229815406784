@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(89, 203, 157, 0.8) rgba(0, 0, 0, 0.99);
}
::-webkit-scrollbar {
  width: 13px;
  height: auto;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.099);
}

::-webkit-scrollbar-thumb {
  background: rgba(196, 147, 162, 0.8);
  border-radius: 30px;
}
:root {
  --black: rgba(0, 0, 0);
  --dim-black: rgb(29, 30, 43);
  --light-black: rgb(35, 43, 59);
  --dim-white: hsl(240, 100%, 99%);
  --yellow: rgba(252, 211, 65);
  --pink: rgb(255, 0, 122);
  --dimpink: #ff014f;
  --white: rgb(255, 255, 255);
  --smoke-white: #f5f5f5;
  --dark-text: #333;

  --btn1-background: var(--dim-white);
  --btn1-box-shadow: 0px 5px 5px rgba(253, 0, 211, 0.1);
  --btn1-border-radius: 30px;

  --btn2-background: var(--pink);
  --btn2-box-shadow: 0px 10px 20px rgba(255, 0, 122, 0.1);
  --btn2-border-radius: 30px;

  --hero-text-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 10px 20px 5px var(--dim-black);

  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4cb256;
  --video: #4a4eb7;
  --location: #ef5757;
  --shedule: #e1ae4a;
  --container-width-lg: 95%;
  --container-width-md: 92%;
  --container-width-sm: 95%;
  --transition: all 400ms ease;
  --font-family-poppins: "Poppins";
  --font-family-roboto: "Roboto";

  /* Font size (covert to rem later) */
  --font-size-xs: 14.8px;
  --font-size-s: 16px;
  --font-size-m: 22px;
  --font-size-l: 28px;
  --font-size-xl: 36px;
  --font-size-xxl: 40px;
}

/* fonts */
@font-face {
  font-family: "Roboto";
  src: url(./Assets/fonts/roboto/Roboto-Light.woff2) format(woff2);
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url(./Assets//fonts//roboto/Roboto-Regular.woff2) format(woff2);
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(./Assets/fonts/roboto/Roboto-Medium.woff2) format(woff2);
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(./Assets/fonts/roboto/Roboto-Bold.woff2);
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url(./Assets/fonts/roboto/Roboto-Black.woff2) format(woff2);
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url(./Assets/fonts/poppins/Poppins-Light.woff2) format(woff2);
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url(./Assets/fonts/poppins/Poppins-Regular.woff2) format(woff2);
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url(./Assets/fonts/poppins/Poppins-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url(./Assets/fonts/poppins/Poppins-Bold.woff2);
  font-weight: 700;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

/* GENERAL STYLES */

html,
#root,
body {
  height: 100%;
  min-height: 100vh;
}

body {
  margin: auto;
  font-family: var(--font-family-poppins);
  background: white;
  color: var(--dark-text);
}

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

.box_shadow {
  padding: 3rem;
  box-shadow: 0px 10px 20px 5px rgb(40 40 46 / 10%),
    0px 6px 4px rgb(92 78 78 / 25%);
  border-radius: 10px;
}

.box_shadow-2 {
  background: white;

  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 11%);

  border-radius: 10px;
}

.btn_shadow {
  padding: 18px 25px;
  border-radius: 6px;
  transition: 0.4s all ease;
  cursor: pointer;
  color: var(--pink);
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, 4px -4px 8px #ffffff;
}

.btn_shadow:hover {
  background: var(--dimpink);
  color: white;
  transform: translateY(-10px);
}

.d_flex {
  display: flex;
  justify-content: space-between;
}

.everyleft,
.everyright,
.everyrow {
  width: 50%;
}

.mtop {
  margin-top: 4rem;
}

.text-center {
  text-align: center;
}

.overlay::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.overlay2::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0.83%,
    rgba(0, 0, 0, 0.67) 66.25%
  );
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.fx-gr-2 {
  flex-grow: 2;
}

.column {
  flex-direction: column;
}

.mt-48 {
  margin-top: 48px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-24 {
  margin-top: 24px;
}

.align-items-center {
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}
